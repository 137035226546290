import React, { Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

function Model({ url }) {
  const gltf = useGLTF(url);
  const ref = useRef();
  useFrame((state, delta) => (ref.current.rotation.y += delta));
  return <primitive ref={ref} object={gltf.scene} scale={4} />;
}

const AssetViewer = (props) => {
  return (
    <>
      <Canvas>
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <Suspense fallback={null}>
          <Model url={props.url || 'https://mirageassets.blob.core.windows.net/mirage-asset-cupboard/astronaut.glb'} />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </>
  );
};
export default AssetViewer;
