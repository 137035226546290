import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from 'react-redux';
import "./App.css";
import Login from "./pages/login/index";
import DefaultLayout from "./layouts/defaulLayout";
import Home from "./pages/home/index";
import Account from "./pages/account/index";
import Assets from "./pages/assets";
import Subscription from "./pages/subscription";
import AssetViewer from "./components/assetViewer";

const masterConfig = require("./data/masterconfig.json");

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  return isAuthenticated ? element : <Login />;
};

const routesConfig = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <DefaultLayout />,
    children: [
      {
        path: "/*",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/account",
        element: <ProtectedRoute element={<Account />} />,
      },
      {
        path: "/asset",
        element: <ProtectedRoute element={<Assets />} />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

function App() {
  return (
    <>
      {masterConfig.constructionPage ? (
        <>
          <div className="black-bar">under construction</div>
          <div className="app-body">
            <AssetViewer
              url={
                "https://mirageassets.blob.core.windows.net/mirage-asset-cupboard/3dtext.glb"
              }
            />
          </div>
        </>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
