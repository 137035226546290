import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAssetsByUserId } from '../../action';

const Assets = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const assets = useSelector((state) => state.assets);

  useEffect(() => {
    dispatch(getAssetsByUserId(user.user_id));
  }, [user, user.user_id]);

  return (
    <>
      <div className="assets blur-glass">My Collections</div>
      <div className="container collection-list">
       {
        assets.length > 0 && assets.map((asset, index) => {
          return(<>
            <div className="asset-card light-glass">{asset.asset_name}</div>
          </>)
        })
       }
      </div>
    </>
  );
};
export default Assets;
